let msCSS = document.createElement( "link" )
msCSS.rel = 'stylesheet'
msCSS.type = "text/css"
msCSS.href = "https://cdnjs.cloudflare.com/ajax/libs/multi-select/0.9.12/css/multi-select.min.css"
msCSS.onload = function(){
    console.log( "jQuery multi-select style file Loaded:" + msCSS.href )
}
document.head.appendChild( msCSS )

$.getScript( "https://cdnjs.cloudflare.com/ajax/libs/multi-select/0.9.12/js/jquery.multi-select.min.js", function() {
    console.log( "jQuery multi-select JS loaded" )
})
